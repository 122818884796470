.App {
  width: 100vw;
}

iframe {
  width: 100%;
  height: 315px;
}

#App-header {
  /* z-index: 1; */
  /* height: 10vh; */
  /* width: 100%; */
  background-color: white;
}
.mr-auto {
  background-color: white;
}

#navbar-icons {
  justify-self: end;
}

#card-header:first-child {
  text-align: center;
}

#App-pages {
  width: 100%;
  background-image: url("../Images/jl_logo.png");
  background-color: rgba(255, 255, 255, 0.9);
  background-blend-mode: lighten;
}
.home {
  height: 80vh;
  font-weight: 400;
}

#portfolio-page {
  font-weight: 400;
}

#contact-page {
  font-weight: 400;
}

#about-page {
  font-weight: 400;
}

#contact-form {
  text-align: start;
}
#card-shadow {
  border: 1px solid lightgrey;
  padding: 10px;
  box-shadow: 5px 10px 8px #000000;
}

.card-shadow {
  border: 1px solid lightgrey;
  padding: 10px;
  box-shadow: 5px 10px 8px #000000;
}

#title-pic-img {
  margin: 0;
  height: 70vh;
  padding-left: 10%;
}

#jlimg {
  box-sizing: border-box;
  height: inherit;
  object-fit: contain;
  border: 1px solid black;
  background-color: white;
  padding: 10px;
  box-shadow: 5px 10px 8px #000000;
}

#title-pic-card {
  color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  height: auto;
  width: auto;
  overflow: hidden;
  border: none;
  margin-top: 40vh;
  animation-duration: 3s;
  animation-name: slidein;
  animation-direction: alternate;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    width: 100%;
  }
}

#portfolio-page-ul a {
  color: #000;
  text-decoration: none;
}
#portfolio-page-ul a:hover {
  color: slategray;
  text-decoration: none;
}

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: red; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

#myBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

#sbmt {
  color: rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  border: rgba(0, 0, 0, 1);
  border-style: solid;
}
#sbmt:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 1);
  border: rgba(0, 0, 0, 1);
  border-style: solid;
}

/* MEDIA */
@media only screen and (max-width: 500px) {
  #title-pic-img {
    margin: 0;
    padding: 0;
    /* max-height: 70vh; */
    /* overflow:hidden; */
  }

  #jlimg {
    box-sizing: border-box;
    max-width: 100%;

    margin: 0;

    object-fit: contain;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid black;
    background-color: transparent;
    padding: 0px;
  }

  #App-header {
    /* z-index: 1; */
    padding-top: 0;
    margin-top: 0;
    height: 10vh;
    width: 100%;
    background-color: white;
  }
  iframe {
    width: 100%;
    height: 100%;
  }

  #App-pages {
    min-height: 80vh;
    width: 100%;
  }
  .mr-auto {
    background-color: white;
  }

  /* img {
    object-fit: scale-down;
    object-position: center;
  } */

  .home {
    /* max-height: 70%; */
    /* margin-top: -10vh;
    overflow: hidden;
    background-image: url("../Images/TitlePicture2.png");
    background-attachment: absolute;
    background-repeat: no-repeat;
    background-size: cover;

    background-position-x: 40%;
    justify-items: center;
    align-items: center; */
  }
  #title-pic-card {
    color: white;
    background-color: rgba(0, 0, 0, 0);
    height: auto;
    width: auto;
    overflow: hidden;
    border: none;
    position: absolute;
    bottom: 0;
    text-align: end;
    animation-duration: 3s;
    animation-name: slidein;
    animation-direction: alternate;
  }

  @keyframes slidein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  #myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: rgba(92, 11, 11, 0.5); /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 5px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 1em; /* Increase font size */
  }

  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }
}
